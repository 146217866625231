import { REQUEST_STATUSES } from "../constants";
import { api } from "./instance";
import { getErrorResponse } from "../utils/helper";

export class PropertyService {
  static async getProperties(query) {
    try {
      const resp = await api({
        method: "get",
        url: "/api/v2/properties",
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [], count: 0 },
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getProperty(propertyId) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/properties/${propertyId}`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async addProperty(data) {
    try {
      const resp = await api({
        method: "post",
        url: "/api/v2/properties",
        data,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async updateProperty(propertyId, data) {
    try {
      const resp = await api({
        method: "put",
        url: `/api/v2/properties/${propertyId}`,
        data,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async addPropertyV2(data) {
    try {
      const resp = await api({
        method: "post",
        url: "/api/v2/properties/v2",
        data,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getPropertyV2(propertyId) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/properties/v2/${propertyId}`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async updatePropertyV2(propertyId, formData) {
    try {
      const resp = await api({
        method: "put",
        url: `/api/v2/properties/v2/${propertyId}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
