import { REQUEST_STATUSES } from "../constants";
import { api } from "./instance";
import { getErrorResponse } from "../utils/helper";

export class PresentationService {
  static async getSearchOptions(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/presentations/search/options/${propertyId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: {},
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getContentTypes(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/presentations/content-types/${propertyId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [] },
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getTags(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/presentations/tags/${propertyId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [] },
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getMediaItems(propertyId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/presentations/media-items/${propertyId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [] },
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
