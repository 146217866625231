export const SORT_FIELD = {
  // user
  USER_NAME: "name",
  USER_EMAIL: "email",
  USER_PHONE: "phone",
  USER_LAST_LOGIN: "lastLogin",
  USER_NUM_OF_PROPERTIES: "numOfProperties",
  USER_STATUS: "status",
  USER_UPDATED_AT: "updatedAt",
  // property
  PROPERTY_NAME: "name",
  PROPERTY_STATUS: "status",
  PROPERTY_NUM_OF_MEMBERS: "numOfMembers",
  PROPERTY_NUM_OF_MEDIA_ITEMS: "numOfMediaItems",
  PROPERTY_CREATED_BY: "createdBy",
  PROPERTY_CREATED_AT: "createdAt",
  PROPERTY_UPDATED_AT: "updatedAt",
  // media-item
  MEDIA_ITEM_NAME: "name",
  MEDIA_ITEM_TYPE: "type",
  MEDIA_ITEM_NUM_OF_TAGS: "numOfTags",
  MEDIA_ITEM_SIZE: "size",
  MEDIA_ITEM_CREATED_BY: "createdBy",
  MEDIA_ITEM_NUM_OF_MEMBERS: "numOfMembers",
  MEDIA_ITEM_CREATED_AT: "createdAt",
  // member
  MEMBER_NAME: "name",
  MEMBER_EMAIL: "email",
  MEMBER_PHONE: "phone",
  MEMBER_STATUS: "status",
  MEMBER_ROLE: "role",
  // tag
  TAG_NAME: "name",
  TAG_ORDER: "order",
  // experience
  EXPERIENCE_TITLE: "title",
  EXPERIENCE_LOCKED: "locked",
  EXPERIENCE_FAVORITES: "favorites",
  EXPERIENCE_VIEWS: "views",
  EXPERIENCE_NUM_OF_MEMBERS: "numOfMembers",
  EXPERIENCE_NUM_OF_CONTACTS: "numOfContacts",
  EXPERIENCE_ACTIVITY: "activity",
  EXPERIENCE_UPDATED_AT: "updatedAt",
  EXPERIENCE_CREATED_AT: "createdAt",
  // univ category
  UNIV_CATEGORY_NAME: "name",
  UNIV_CATEGORY_ORDER: "order",
  // univ video
  UNIV_VIDEO_TITLE: "title",
  UNIV_VIDEO_DURATION: "duration",
  UNIV_VIDEO_ORDER: "order",
  // icon
  ICON_NAME: "name",
  // contact
  CONTACT_NAME: "name",
  CONTACT_EMAIL: "email",
  CONTACT_STATUS: "status",
  CONTACT_PHONE: "phone",
  CONTACT_NEXT_ACTION_DATE: "nextActionDate",
  CONTACT_ACTIVITY: "activity",
  CONTACT_NUM_OF_EXPERIENCES: "numOfExperiences",
  CONTACT_CREATED_AT: "createdAt",
  CONTACT_UPDATED_AT: "updatedAt",
  // notification
  NOTIFICATION_CREATED_AT: "createdAt",
  NOTIFICATION_UPDATED_AT: "updatedAt",
  // notification
  ELEMENT_CREATED_AT: "createdAt",
  ELEMENT_UPDATED_AT: "updatedAt",
  // contact note
  CONTACT_NOTE_TYPE: "type",
  CONTACT_NOTE_CREATED_AT: "createdAt",
  CONTACT_NOTE_UPDATED_AT: "updatedAt",
  // hotspot
  HOTSPOT_ORDER: "order",
  HOTSPOT_CREATED_AT: "createdAt",
  HOTSPOT_UPDATED_AT: "updatedAt",
  // content type
  CONTENT_TYPE_NAME: "name",
  CONTENT_TYPE_ORDER: "order",
  CONTENT_TYPE_VALUE: "value",
  // accounts
  ACCOUNTS: "salesforceName",
  DISPLAY_NAME: "displayName",
  ORG_TYPE: "organizationType",
  PROPERTY_COUNT: "propertyCount",
} as const;

export const SORT_ORIENTATION = {
  ASC: "ASC",
  DESC: "DESC",
} as const;
