import type { AxiosError } from "axios";

import type { Experience } from "@/Dashboard/pages/ExperiencesV2/types";
import { REQUEST_STATUSES } from "@/constants";
import { getErrorResponse } from "@/utils/helper";

import instance, { api } from "./instance";

export class ExperienceService {
  static async getExperiences(propertyId: number, query?: unknown) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/experiences/${propertyId}`,
        params: query,
      });

      return {
        resp: resp.data as { data: Experience[]; count: number },
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [], count: 0 },
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  // TODO: Query is not used in the backend
  static async getExperience(propertyId: number, experienceId: number, query?: unknown) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/experiences/${propertyId}/${experienceId}`,
        params: query,
      });

      return {
        resp: resp.data as Experience,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async addExperience(propertyId: number, data?: unknown) {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/experiences/${propertyId}`,
        data,
      });

      return {
        resp: resp.data as Experience,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async addDuplicateFullCollection(propertyId: number, data?: unknown) {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/experiences/duplicate/full-collection/${propertyId}`,
        data,
      });

      return {
        resp: resp.data as Experience,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async addDuplicateExperience(propertyId: number, experienceId: number, data?: unknown) {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/experiences/duplicate/${propertyId}/${experienceId}`,
        data,
      });

      return {
        resp: resp.data as Experience,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async updateExperience(propertyId: number, experienceId: number, data?: unknown) {
    try {
      const resp = await api({
        method: "put",
        url: `/api/v2/experiences/${propertyId}/${experienceId}`,
        data,
      });

      return {
        resp: resp.data as Experience,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async updatePublicExperience(propertyId: number, experienceId: number, data: { activity: string }) {
    try {
      const resp = await instance({
        method: "put",
        url: `/api/v2/public/experiences/${propertyId}/${experienceId}`,
        data: {
          activity: data.activity,
        },
      });

      return {
        resp: resp.data as Experience,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async deleteExperience(propertyId: number, experienceId: number) {
    try {
      const resp = await api({
        method: "delete",
        url: `/api/v2/experiences/${propertyId}/${experienceId}`,
      });

      return {
        resp: resp.data as Experience,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async bulkDeleteExperience(propertyId: number, data?: unknown) {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/experiences/bulk/delete/${propertyId}`,
        data,
      });

      return {
        resp: resp.data as Experience,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
