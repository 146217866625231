import { type PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { Experience, ExperienceFilters } from "@/Dashboard/pages/ExperiencesV2/types";
import { filterExperiences } from "@/Dashboard/pages/ExperiencesV2/utils/experienceFilters";
import { sortExperiences } from "@/Dashboard/pages/ExperiencesV2/utils/experienceSort";
import { REQUEST_STATUSES } from "@/constants";
import type { EXPERIENCE_CATEGORY } from "@/constants/experience";
import type { selectUser } from "@/store/auth/auth.slice";
import type { State } from "@/store/types";

import { ExperienceService } from "../../../services";

interface ExperiencesState {
  [propertyId: number]: typeof defaultState;
}

const defaultState = {
  data: [] as Experience[],
  status: REQUEST_STATUSES.IDLE as REQUEST_STATUSES,
  err: null as unknown,
  noResults: false as boolean,
} as const;

const initialState: ExperiencesState = {};

export const fetchExperiencesV2 = createAsyncThunk(
  "experiencesV2/fetchExperiences",
  async ({ propertyId, query }: { propertyId: number; query?: unknown }) => {
    const { resp, status, err } = await ExperienceService.getExperiences(propertyId, query);
    const { data: experiences = [], count = 0 } = resp || {};

    return {
      propertyId,
      experiences,
      count,
      status,
      err,
      query,
    };
  },
);

const experiencesV2Slice = createSlice({
  name: "experiencesV2",
  initialState,
  reducers: {
    updateExperience: (state, action: PayloadAction<{ propertyId: number; experience: Experience }>) => {
      const { propertyId, experience } = action.payload;
      const data = state[propertyId]?.data || [];
      const index = data.findIndex((x) => x.id === experience.id);
      if (index !== -1) {
        data[index] = experience;
        state[propertyId].data = [...data];
      }
    },

    deleteExperiences: (state, action: PayloadAction<{ propertyId: number; experienceIds: number[] }>) => {
      const { propertyId, experienceIds } = action.payload;
      if (state[propertyId]) {
        state[propertyId].data = state[propertyId].data.filter((experience) => !experienceIds.includes(experience.id));
      }
    },

    resetExperiences: (state, action: PayloadAction<{ propertyId: number }>) => {
      const { propertyId } = action.payload;
      delete state[propertyId];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExperiencesV2.pending, (state, action) => {
        const propertyId = action.meta.arg.propertyId;
        if (!state[propertyId]) {
          state[propertyId] = { ...defaultState };
        }
        state[propertyId].status = REQUEST_STATUSES.LOADING;
        state[propertyId].err = null;
      })
      .addCase(fetchExperiencesV2.fulfilled, (state, action) => {
        const { propertyId, experiences, status, err } = action.payload;
        state[propertyId] = {
          ...state[propertyId],
          data: experiences,
          status,
          err,
          noResults: experiences.length === 0,
        };
      })
      .addCase(fetchExperiencesV2.rejected, (state, action) => {
        const propertyId = action.meta.arg.propertyId;
        if (state[propertyId]) {
          state[propertyId].status = REQUEST_STATUSES.FAILED;
          state[propertyId].err = action.error;
        }
      });
  },
});

export const experiencesV2Selector = (propertyId: number) => {
  return (state: State) => ({
    ...defaultState,
    ...state.experiencesV2[propertyId],
  });
};

export const selectFilteredAndSortedExperiences = ({
  propertyId,
  experienceCategory,
  filters,
  searchText,
  sortField,
  sortOrientation,
  user,
}: {
  propertyId: number;
  experienceCategory: EXPERIENCE_CATEGORY;
  filters: ExperienceFilters;
  searchText: string;
  sortField: string;
  sortOrientation: string;
  user: ReturnType<typeof selectUser>;
}) => {
  return (state: State) => {
    const { data = [] } = state.experiencesV2[propertyId] || {};

    const filtered = filterExperiences(data, experienceCategory, filters, searchText, user);

    return sortExperiences(filtered, sortField, sortOrientation);
  };
};

export default experiencesV2Slice.reducer;
