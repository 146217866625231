import { createSelector } from "@reduxjs/toolkit";

import type { Asset } from "@/Dashboard/pages/AssetLibrary/types";

interface CurrentSelectedItems {
  selectAll: boolean;
  selectMode: boolean;
  selectedItems: Record<string, Asset>;
  changed: number;
}

interface CurrentSelectedItemsState {
  currentSelectedItems: CurrentSelectedItems;
}

export const currentSelectedItemsSelector = createSelector(
  (state: CurrentSelectedItemsState) => state.currentSelectedItems,
  (currentSelectedItems) => {
    return { ...currentSelectedItems };
  },
);
