import { EXPERIENCE_PRIMARY_FILTER, SHARE_SCOPE, TAB_STATUSES, VB_MODE } from "../constants";

/**
 * Object containing functions to generate unique keys for different visualization modes
 * These keys are used to identify and store state for different views
 */
export const VisualBuilderKeys = {
  // Generate key for presentation mode using property ID
  presentation: (propertyId) => propertyId,

  // Generate key for visualization mode using property, experience and user IDs
  visualization: (propertyId, experienceId, userId) => `${propertyId}/${experienceId}/${userId}`,

  // Generate key for public visualization using property and experience IDs
  publicVisualization: (propertyId, experienceId) => `${propertyId}/${experienceId}`,

  // Generate key for single share visualization using property and media item IDs
  publicSingleShareVisualization: (propertyId, mediaItemId) => `${propertyId}/${mediaItemId}`,

  // Generate key for media share visualization using property and share IDs
  publicMediaShareVisualization: (propertyId, shareId) => `${propertyId}/${shareId}`,
};

/**
 * Determine the visualization mode and key based on provided parameters
 * @param {Object} params - Object containing route and state parameters
 * @returns {Object} Object containing key and mode for the visualization
 */
export function getVisualBuilderMode(params) {
  const { propertyId, experienceId, userId, tabStatus, shareId, mediaItemId } = params;
  const values = {};

  // Handle homebase and collect tabs
  if ([TAB_STATUSES.HOMEBASE, TAB_STATUSES.COLLECT].includes(tabStatus)) {
    values.key = VisualBuilderKeys.presentation(propertyId);
    values.mode = VB_MODE.PRESENTATION;
  }

  // Handle customize and share tabs with user context
  if (userId && [TAB_STATUSES.CUSTOMIZE, TAB_STATUSES.SHARE].includes(tabStatus)) {
    values.key = VisualBuilderKeys.visualization(propertyId, experienceId, userId);
    values.mode = VB_MODE.VISUALIZATION;
  }

  // Handle public tab
  if (tabStatus === TAB_STATUSES.PUBLIC) {
    values.key = VisualBuilderKeys.publicVisualization(propertyId, experienceId);
    values.mode = VB_MODE.PUBLIC_VISUALIZATION;
  }

  // Handle public single share tab
  if (tabStatus === TAB_STATUSES.PUBLIC_SINGLE_SHARE) {
    values.key = VisualBuilderKeys.publicSingleShareVisualization(propertyId, mediaItemId);
    values.mode = VB_MODE.PUBLIC_SINGLE_SHARE_VISUALIZATION;
  }

  // Handle public media share tab
  if (tabStatus === TAB_STATUSES.PUBLIC_MEDIA_SHARE) {
    values.key = VisualBuilderKeys.publicMediaShareVisualization(propertyId, shareId);
    values.mode = VB_MODE.PUBLIC_MEDIA_SHARE_VISUALIZATION;
  }

  return values;
}

/**
 * Transform content types data to establish relationships and ordering
 * @param {Object} payload - Current state payload
 * @returns {Object} Updated payload with transformed content types data
 */
export function transformContentTypesData(payload) {
  // Initialize relationship containers
  payload.contentTypeAssetCategories = {};
  payload.contentTypeTags = {};

  // Process each content type
  payload.contentTypes.forEach((contentType) => {
    // Set or preserve order
    payload.contentTypesOrdered[contentType.id] = payload.contentTypesOrdered[contentType.id] || contentType.order;
    // Initialize relationships arrays
    payload.contentTypeTags[contentType.id] = [];
    payload.contentTypeAssetCategories[contentType.id] = [];
  });

  return payload;
}

/**
 * Transform tags data to establish relationships and ordering
 * @param {Object} payload - Current state payload
 * @returns {Object} Updated payload with transformed tags data
 */
export function transformTagsData(payload) {
  // Initialize relationship containers
  payload.tagContentTypes = {};
  payload.assetCategoryContentTypes = {};

  // Process each tag
  payload.tags.forEach((tag) => {
    payload.tagContentTypes[tag.id] = [];
    payload.hiddenTags[`media/${tag.id}`] = tag.hidden;
    payload.tagsOrdered[tag.id] = payload.tagsOrdered[tag.id] || tag.order;
  });

  // Add "Other" asset category
  payload.assetCategories?.push({
    id: -1,
    name: "Other",
  });

  // Process each asset category
  payload.assetCategories?.forEach((category) => {
    payload.assetCategoryContentTypes[category.id] = [];
    payload.assetCategoriesOrdered[category.id] = payload.assetCategoriesOrdered[category.id] || category.order || 0;
  });

  return payload;
}

/**
 * Transform media item data to establish relationships and handle special cases
 * @param {Object} payload - Current state payload
 * @returns {Object} Updated payload with transformed media item data
 */
export function transformMediaItemData(payload) {
  payload.mediaItemsOrdered = {};

  // Ensure mediaItems is an array
  if (!Array.isArray(payload.mediaItems)) {
    payload.mediaItems = [payload.mediaItems];
  }

  // Process each media item
  payload.mediaItems = payload.mediaItems.map((mediaItem) => {
    mediaItem.tags = [];

    // Handle asset category relationships
    if (payload.assetCategoryContentTypes?.[mediaItem.assetCategoryId || -1]) {
      payload.assetCategoryContentTypes[mediaItem.assetCategoryId || -1].push(mediaItem.contentTypeId);
    }

    // Handle media items with no tags
    if (!mediaItem.mediaItemTags.length) {
      mediaItem.mediaItemTags.push({
        ...payload.otherTag,
        tagId: payload.otherTag.id,
        mediaItemId: mediaItem.id,
      });
    }

    // Process media item tags
    if (mediaItem.mediaItemTags.length) {
      mediaItem.mediaItemTags.forEach((mediaItemTag) => {
        if (payload.tagContentTypes[mediaItemTag.id]) {
          payload.tagContentTypes[mediaItemTag.id].push(mediaItem.contentTypeId);
        }

        mediaItem.tags.push(mediaItemTag.id);
      });

      // Handle hidden tags
      const isAllTagsHidden = mediaItem.mediaItemTags.every((item) =>
        isPublicPage(payload.tabStatus) ? item?.hidden || !!payload.hiddenTags[item.id] : item?.hidden,
      );

      if (isAllTagsHidden) {
        mediaItem.tags.push(payload.otherTag.id);
        mediaItem.mediaItemTags.push({
          ...payload.otherTag,
          tagId: payload.otherTag.id,
          mediaItemId: mediaItem.id,
        });
      }
    }

    // Update content type relationships
    payload.contentTypeTags[mediaItem.contentTypeId] = payload.contentTypeTags[mediaItem.contentTypeId]?.concat(
      mediaItem.tags,
    );

    if (payload.contentTypeAssetCategories?.[mediaItem.contentTypeId]) {
      payload.contentTypeAssetCategories[mediaItem.contentTypeId] = payload.contentTypeAssetCategories[
        mediaItem.contentTypeId
      ].concat(mediaItem.assetCategoryId || -1);
    }

    return mediaItem;
  });

  // Process ordering
  Object.keys(payload.mediaItemsOrdered).forEach((key) => {
    payload.mediaItemsOrdered[key] = Math.min(...payload.mediaItemsOrdered[key]);
  });

  // Deduplicate relationships
  Object.keys(payload.contentTypeTags).forEach(
    (typeId) => (payload.contentTypeTags[typeId] = [...new Set(payload.contentTypeTags[typeId])]),
  );
  Object.keys(payload.tagContentTypes).forEach(
    (tagId) => (payload.tagContentTypes[tagId] = [...new Set(payload.tagContentTypes[tagId])]),
  );

  Object.keys(payload.contentTypeAssetCategories || {}).forEach(
    (typeId) => (payload.contentTypeAssetCategories[typeId] = [...new Set(payload.contentTypeAssetCategories[typeId])]),
  );
  Object.keys(payload.assetCategoryContentTypes || {}).forEach(
    (catId) => (payload.assetCategoryContentTypes[catId] = [...new Set(payload.assetCategoryContentTypes[catId])]),
  );

  // Update content types with relationships
  payload.contentTypes = payload.contentTypes.map((contentType) => {
    contentType.tags = payload.contentTypeTags[contentType.id];
    contentType.assetCategories = payload.contentTypeAssetCategories?.[contentType.id];
    return contentType;
  });

  // Update tags with relationships
  payload.tags = payload.tags.map((tag) => {
    tag.contentTypes = payload.tagContentTypes[tag.id];
    return tag;
  });

  // Update asset categories with relationships
  if (payload.assetCategories) {
    payload.assetCategories = payload.assetCategories.map((category) => {
      category.contentTypes = payload.assetCategoryContentTypes[category.id];
      return category;
    });
  }

  return payload;
}

/**
 * Filter media items based on current filters and search criteria
 * @param {Object} payload - Current state payload
 * @returns {Object} Updated payload with filtered media items
 */
export function getVisualBuilderFilteredMediaItems(payload) {
  const { mediaItems = [], typeFilterId, assetCategoryFilterId, tagFilterId, search = {} } = payload;
  const { term = "", searchText = "", contentTypeIds = [], tagIds = [] } = search;

  // Initialize filtered collections
  payload.filteredMediaItems = [...mediaItems];
  payload.validContentTypes = {};
  payload.validTags = {};
  payload.contentTypeMediaItems = {};
  payload.filteredContentTypeMediaItems = {};
  payload.tagMediaItems = { [payload.otherTag.id]: [] };
  payload.filteredTagMediaItems = { [payload.otherTag.id]: [] };
  payload.validAssetCategories = {};
  payload.assetCategoryMediaItems = { [-1]: [] };
  payload.filteredAssetCategoryMediaItems = { [-1]: [] };

  // Build relationship collections
  payload.filteredMediaItems.forEach((mediaItem) => {
    // Handle content type relationships
    if (!payload.contentTypeMediaItems[mediaItem.contentTypeId]) {
      payload.contentTypeMediaItems[mediaItem.contentTypeId] = [mediaItem];
    } else {
      payload.contentTypeMediaItems[mediaItem.contentTypeId].push(mediaItem);
    }

    // Handle tag relationships
    mediaItem.tags.forEach((tagId) => {
      if (!payload.tagMediaItems[tagId]) {
        payload.tagMediaItems[tagId] = [{ ...mediaItem, tagId }];
      } else {
        payload.tagMediaItems[tagId].push({ ...mediaItem, tagId });
      }
    });

    // Handle asset category relationships
    if (payload.assetCategoryMediaItems) {
      if (payload.assetCategoryMediaItems[mediaItem.assetCategoryId || -1]) {
        payload.assetCategoryMediaItems[mediaItem.assetCategoryId || -1].push({
          ...mediaItem,
          assetCategoryId: mediaItem.assetCategoryId || -1,
        });
      } else {
        payload.assetCategoryMediaItems[mediaItem.assetCategoryId || -1] = [
          { ...mediaItem, assetCategoryId: mediaItem.assetCategoryId || -1 },
        ];
      }
    }
  });

  // Apply content type and tag filters
  if (contentTypeIds.length || tagIds.length) {
    payload.filteredMediaItems = payload.filteredMediaItems.filter((mediaItem) => {
      if (search.searchAndOr) {
        return (
          contentTypeIds.some((contentTypeId) => mediaItem.contentTypeId === contentTypeId) ||
          tagIds.some((tagId) => mediaItem.tags.includes(tagId))
        );
      }

      return (
        contentTypeIds.some((contentTypeId) => mediaItem.contentTypeId === contentTypeId) &&
        tagIds.every((tagId) => mediaItem.tags.includes(tagId))
      );
    });
  }

  // Apply search term filter
  if (term.length) {
    payload.filteredMediaItems = payload.filteredMediaItems.filter((mediaItem) => {
      if (!mediaItem.name) return false;
      return mediaItem.name.toLowerCase().trim().indexOf(term.toLowerCase().trim()) > -1;
    });
  }

  // Apply search text filter
  if (searchText.length) {
    payload.filteredMediaItems = payload.filteredMediaItems.filter((mediaItem) => {
      if (!mediaItem.name) return false;
      return mediaItem.name.toLowerCase().includes(searchText.toLowerCase().trim());
    });
  }

  // Build filtered relationships
  payload.filteredMediaItems.forEach((mediaItem) => {
    // Handle filtered content type relationships
    if (!payload.filteredContentTypeMediaItems[mediaItem.contentTypeId]) {
      payload.filteredContentTypeMediaItems[mediaItem.contentTypeId] = [mediaItem];
    } else {
      payload.filteredContentTypeMediaItems[mediaItem.contentTypeId].push(mediaItem);
    }

    // Skip if primary filter doesn't match
    if (
      payload.primaryFilter === EXPERIENCE_PRIMARY_FILTER.TYPE &&
      payload.typeFilterId &&
      mediaItem.contentTypeId !== payload.typeFilterId
    ) {
      return;
    }

    // Handle filtered tag relationships
    if (mediaItem.tags.length) {
      mediaItem.tags.forEach((tagId) => {
        payload.validTags[tagId] = true;

        if (!payload.filteredTagMediaItems[tagId]) {
          payload.filteredTagMediaItems[tagId] = [{ ...mediaItem, tagId }];
        } else {
          payload.filteredTagMediaItems[tagId].push({ ...mediaItem, tagId });
        }
      });
    } else {
      payload.filteredTagMediaItems[payload.otherTag.id].push({ ...mediaItem, tagId: payload.otherTag.id });
    }

    // Handle filtered asset category relationships
    if (payload.filteredAssetCategoryMediaItems) {
      if (payload.filteredAssetCategoryMediaItems[mediaItem.assetCategoryId || -1]) {
        payload.filteredAssetCategoryMediaItems[mediaItem.assetCategoryId || -1].push({
          ...mediaItem,
          assetCategoryId: mediaItem.assetCategoryId || -1,
        });
      } else {
        payload.filteredAssetCategoryMediaItems[mediaItem.assetCategoryId || -1] = [
          { ...mediaItem, assetCategoryId: mediaItem.assetCategoryId || -1 },
        ];
      }
    }
  });

  // Apply type filter
  if (typeFilterId) {
    payload.filteredMediaItems = payload.filteredMediaItems.filter(
      (mediaItem) => mediaItem.contentTypeId === typeFilterId,
    );
  }

  // Handle single-menu-redesign flag
  if (payload.flags?.["single-menu-redesign"]) {
    if (
      assetCategoryFilterId !== undefined &&
      assetCategoryFilterId !== null &&
      !!payload.filteredAssetCategoryMediaItems[assetCategoryFilterId]?.length
    ) {
      payload.filteredMediaItems = payload.filteredMediaItems.filter(
        (mediaItem) => (mediaItem.assetCategoryId || -1) === (assetCategoryFilterId || -1),
      );
    }

    // Reset asset category filter if no items match
    if (
      assetCategoryFilterId !== undefined &&
      assetCategoryFilterId !== null &&
      !payload.filteredAssetCategoryMediaItems[assetCategoryFilterId]?.length &&
      payload.filteredMediaItems.length
    ) {
      payload.assetCategoryFilterId = payload.filteredMediaItems[0].assetCategoryId || -1;
    }
  } else {
    // Handle standard tag filtering
    if (tagFilterId && !!payload.filteredTagMediaItems[tagFilterId]?.length) {
      payload.filteredMediaItems = payload.filteredMediaItems.filter((mediaItem) =>
        mediaItem.tags.includes(tagFilterId),
      );
    }

    // Reset tag filter if no items match
    if (tagFilterId && !payload.filteredTagMediaItems[tagFilterId]?.length && payload.filteredMediaItems.length) {
      payload.tagFilterId = payload.filteredMediaItems[0].mediaItemTags.find(
        (mediaItemTag) => !mediaItemTag?.hidden,
      ).id;
    }
  }

  // Update valid content types
  payload.filteredMediaItems.forEach((mediaItem) => {
    payload.validContentTypes[mediaItem.contentTypeId] = true;
  });

  // Reset current media item if it's no longer in filtered items
  if (
    payload.filteredMediaItems.length &&
    !payload.filteredMediaItems.find((mediaItem) => mediaItem.id === payload.currentMediaItemId)
  ) {
    payload.currentMediaItemId = payload.filteredMediaItems[0].id;
  }

  // Track if search was performed
  payload.searchPerformed = !!contentTypeIds.length || !!tagIds.length || !!term.length || !!searchText.length;

  return payload;
}

/**
 * Filter tags based on current filters and settings
 * @param {Object} payload - Current state payload
 * @returns {Object} Updated payload with filtered tags
 */
export function getVisualBuilderFilteredTags(payload) {
  const {
    primaryFilter,
    typeFilterId,
    contentTypeTags = {},
    validTags = {},
    hiddenTags = {},
    searchPerformed,
    tagsOrdered = {},
    tagNames = {},
    filteredTagMediaItems = {},
    filteredAssetCategoryMediaItems = {},
    assetCategoriesOrdered = {},
    contentTypeAssetCategories,
    assetCategoryNames = {},
  } = payload;

  const isTabsStatusPublic = isPublicPage(payload.tabStatus);
  const isTabsStatusCustomize = payload.tabStatus === TAB_STATUSES.CUSTOMIZE;

  // Process tags
  payload.tags = payload.tags.map((tag) => {
    tag.hidden = isTabsStatusCustomize || isTabsStatusPublic ? !!hiddenTags[tag.id] : !!hiddenTags[`media/${tag.id}`];
    tag.order = tagsOrdered[tag.id];
    tag.name = tagNames[tag.id] || tag.name;
    return tag;
  });

  // Process asset categories
  payload.assetCategories =
    payload.assetCategories?.map((category) => {
      category.order = assetCategoriesOrdered[category.id];
      category.name = assetCategoryNames[category.id] || category.name;
      return category;
    }) || [];

  // Filter asset categories
  payload.filteredAssetCategories = [...payload.assetCategories].filter((category) => {
    const isHidden = false;
    return !isHidden && filteredAssetCategoryMediaItems?.[category.id]?.length;
  });

  // Filter tags
  payload.filteredTags = [...payload.tags].filter((tag) => {
    const isHidden = isTabsStatusCustomize || isTabsStatusPublic ? !!hiddenTags[`media/${tag.id}`] : tag.hidden;
    return !isHidden && filteredTagMediaItems[tag.id] && filteredTagMediaItems[tag.id].length;
  });

  // Additional public page filtering
  if (isTabsStatusPublic) {
    payload.filteredTags = payload.filteredTags.filter((tag) => !tag.hidden);
  }

  // Search filtering
  if (searchPerformed) {
    payload.filteredTags = payload.filteredTags.filter((tag) => !!validTags[tag.id]);
  }

  // Type filter
  if (primaryFilter === EXPERIENCE_PRIMARY_FILTER.TYPE && typeFilterId) {
    payload.filteredTags = payload.filteredTags.filter((tag) => contentTypeTags[typeFilterId].includes(tag.id));

    payload.filteredAssetCategories =
      payload.filteredAssetCategories?.filter((category) =>
        contentTypeAssetCategories[typeFilterId].includes(category.id),
      ) || [];
  }

  // Handle tag toggling
  if (payload.filteredTags.length && payload.shouldToggleTagItem && !isTabsStatusPublic) {
    if (payload.currentMediaItemId) {
      const { tags = [] } = payload.mediaItems.find(({ id }) => id === payload.currentMediaItemId) ?? {};

      tags.forEach((tagId) => {
        if (payload.filteredTags.find(({ id }) => id === tagId)) {
          payload.tagsToggled = { ...payload.tagsToggled, [tagId]: true };
        }
      });
    } else {
      payload.tagsToggled = { ...payload.tagsToggled, [payload.filteredTags[0].id]: true };
    }
  }

  if (payload.shouldToggleTagItem) {
    payload.shouldToggleTagItem = false;
  }

  return payload;
}

/**
 * Filter content types based on current filters and settings
 * @param {Object} payload - Current state payload
 * @returns {Object} Updated payload with filtered content types
 */
export function getVisualBuilderFilteredContentTypes(payload) {
  const {
    primaryFilter,
    tagFilterId,
    tagContentTypes = {},
    validContentTypes = {},
    searchPerformed,
    hiddenContentTypes,
    contentTypesOrdered,
  } = payload;

  // Process content types
  payload.contentTypes = payload.contentTypes.map((contentType) => {
    contentType.hidden = !!hiddenContentTypes[contentType.id];
    contentType.order = contentTypesOrdered[contentType.id];
    return contentType;
  });

  // Get visible content types
  payload.visibleContentTypes = payload.contentTypes.filter((contentType) => !contentType.hidden);

  // Initialize filtered content types
  payload.filteredContentTypes = [...payload.contentTypes];

  // Handle public page filtering
  if (isPublicPage(payload.tabStatus)) {
    payload.filteredContentTypes = payload.filteredContentTypes.filter(
      (contentType) => !payload.hiddenContentTypes[contentType.id],
    );
  }

  // Search filtering
  if (searchPerformed) {
    payload.filteredContentTypes = payload.filteredContentTypes.filter(
      (contentType) => !!validContentTypes[contentType.id],
    );
  }

  // Tag filter
  if (!payload.flags?.["single-menu-redesign"] && primaryFilter === EXPERIENCE_PRIMARY_FILTER.TAG && tagFilterId) {
    payload.filteredContentTypes = payload.filteredContentTypes.filter((contentType) =>
      tagContentTypes[tagFilterId].includes(contentType.id),
    );
  }

  return payload;
}

/**
 * Sort content types by their order
 * @param {Object} payload - Current state payload
 * @returns {Object} Updated payload with sorted content types
 */
export function getSortedContentTypes(payload) {
  payload.contentTypes = payload.contentTypes.sort(
    (a, b) => payload.contentTypesOrdered[a.id] - payload.contentTypesOrdered[b.id],
  );
  return payload;
}

/**
 * Sort tags and asset categories by their order
 * @param {Object} payload - Current state payload
 * @returns {Object} Updated payload with sorted tags and categories
 */
export function getSortedTags(payload) {
  payload.tags = payload.tags.sort((a, b) => payload.tagsOrdered[a.id] - payload.tagsOrdered[b.id]);

  payload.assetCategories =
    payload.assetCategories?.sort(
      (a, b) => payload.assetCategoriesOrdered[a.id] - payload.assetCategoriesOrdered[b.id],
    ) || [];

  return payload;
}

/**
 * Sort media items by their order
 * @param {Object} payload - Current state payload
 * @returns {Object} Updated payload with sorted media items
 */
export function getSortedMediaItems(payload) {
  payload.mediaItemsOrdered = {};

  // Process ordering for media items
  payload.mediaItems = payload.mediaItems.map((mediaItem) => {
    if (!payload.flags?.["single-menu-redesign"]) {
      mediaItem.mediaItemTags.forEach((mediaItemTag, index) => {
        const mediaId = mediaItemTag.mediaItemId ?? mediaItemTag.MediaItemTag.mediaItemId;
        const mediaItemIndex = payload.contentsOrdered[`${mediaItemTag.id}/${mediaId}`] ?? index + 1;

        if (!payload.mediaItemsOrdered[mediaId]) {
          payload.mediaItemsOrdered[mediaId] = [payload.tagsOrdered[mediaItemTag.id] * 1000 + mediaItemIndex];
        } else {
          payload.mediaItemsOrdered[mediaId].push(payload.tagsOrdered[mediaItemTag.id] * 1000 + mediaItemIndex);
        }
      });
    }

    return mediaItem;
  });

  // Calculate final order values
  Object.keys(payload.mediaItemsOrdered).forEach((key) => {
    payload.mediaItemsOrdered[key] = Math.min(...payload.mediaItemsOrdered[key]);
  });

  // Sort based on redesign flag
  if (payload.flags?.["single-menu-redesign"]) {
    const { typeFilterId, assetCategoryFilterId } = payload;

    if (typeFilterId && assetCategoryFilterId) {
      payload.mediaItems = payload.mediaItems.sort((a, b) => {
        const a1 = payload.experienceAssets?.find(
          (x) => x.contentTypeId === typeFilterId && x.assetCategoryId === assetCategoryFilterId && x.assetId === a.id,
        );
        const b1 = payload.experienceAssets?.find(
          (x) => x.contentTypeId === typeFilterId && x.assetCategoryId === assetCategoryFilterId && x.assetId === b.id,
        );
        return (a1?.order || 0) - (b1?.order || 0);
      });
    }
  } else {
    payload.mediaItems = payload.mediaItems.sort(
      (a, b) => payload.mediaItemsOrdered[a.id] - payload.mediaItemsOrdered[b.id],
    );
  }

  return payload;
}

/**
 * Get drawer options based on current state and settings
 * @param {Object} params - Parameters for drawer options
 * @returns {Object} Drawer options configuration
 */
export function getDrawerOptions({ tabStatus, visibleContentTypes, mediaItems, scope, flags, shareId }) {
  const values = { leftDrawerAutoHide: false, rightDrawerAutoHide: false };

  if (shareId) {
    values.leftDrawer = false;
    values.leftDrawerAutoHide = true;
    return values;
  }

  if (isPublicPage(tabStatus)) {
    if (!flags?.["single-menu-redesign"]) {
      if (visibleContentTypes.length < 1) {
        values.leftDrawerAutoHide = true;
      }

      if (visibleContentTypes.length === 1) {
        values.leftDrawer = false;
      }

      if (mediaItems.length <= 1) {
        values.leftDrawerAutoHide = true;
        values.rightDrawerAutoHide = true;
      }
    } else {
      values.rightDrawerAutoHide = true;
    }
  }

  if (scope === SHARE_SCOPE.MEDIA_ITEM) {
    values.leftDrawerAutoHide = true;
    values.rightDrawerAutoHide = true;
  }

  return values;
}

/**
 * Check if current tab status is a public page
 * @param {string} tabStatus - Current tab status
 * @returns {boolean} Whether the current page is public
 */
export const isPublicPage = (tabStatus) =>
  [TAB_STATUSES.PUBLIC, TAB_STATUSES.PUBLIC_MEDIA_SHARE, TAB_STATUSES.PUBLIC_SINGLE_SHARE].includes(tabStatus);
