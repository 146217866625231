import { REQUEST_STATUSES } from "../constants";
import { api } from "./instance";
import { getErrorResponse } from "../utils/helper";

export class VisualizationService {
  static async getSearchOptions(propertyId, experienceId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/visualizations/search/options/${propertyId}/${experienceId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: {},
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getContentTypes(propertyId, experienceId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/visualizations/content-types/${propertyId}/${experienceId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [] },
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getTags(propertyId, experienceId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/visualizations/tags/${propertyId}/${experienceId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [] },
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getMediaItems(propertyId, experienceId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/visualizations/media-items/${propertyId}/${experienceId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [] },
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
