import { REQUEST_STATUSES } from "../constants";
import { api } from "./instance";
import { getErrorResponse } from "../utils/helper";

export class ExperienceContentTypeService {
  static async getExperienceContentTypes(propertyId, experienceId, query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/experience-content-types/${propertyId}/${experienceId}`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [], count: 0 },
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async addExperienceContentType(propertyId, experienceId, contentTypeId, data) {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/experience-content-types/${propertyId}/${experienceId}/${contentTypeId}`,
        data,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async updateExperienceContentType(propertyId, experienceId, contentTypeId, data) {
    try {
      const resp = await api({
        method: "put",
        url: `/api/v2/experience-content-types/${propertyId}/${experienceId}/${contentTypeId}`,
        data,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
