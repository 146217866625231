import store from "@/store";

export const waitForAuthReady = async (loaderFn: () => Promise<unknown>) => {
  const authState = store.getState().auth;

  if (authState?.status === "success" && authState?.accessToken) {
    return await loaderFn();
  }

  await new Promise<void>((resolve, reject) => {
    const unsubscribe = store.subscribe(() => {
      const updatedAuthState = store.getState().auth;

      if (updatedAuthState?.status === "success" && updatedAuthState?.accessToken) {
        unsubscribe();
        resolve();
      }

      if (updatedAuthState?.status === "error") {
        unsubscribe();
        reject(new Error("Unauthorized: Failed to fetch auth state"));
      }
    });
  });

  return await loaderFn();
};
