import { SORT_FIELD } from "@/constants";

import type { Experience } from "../types";

function sortByCreatedAt(a: Experience, b: Experience, sortOrientation: string) {
  if (sortOrientation === "ASC") {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  } else {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }
}

function sortByName(a: Experience, b: Experience, sortOrientation: string) {
  if (sortOrientation === "ASC") {
    return a.title.localeCompare(b.title);
  } else {
    return b.title.localeCompare(a.title);
  }
}

function sortByActivity(a: Experience, b: Experience, sortOrientation: string) {
  const bActivity = a.activity === "opened" ? 1 : 0;
  const aActivity = b.activity === "opened" ? 1 : 0;

  if (sortOrientation === "ASC") {
    return aActivity - bActivity;
  } else {
    return bActivity - aActivity;
  }
}

function sortByExperienceContacts(a: Experience, b: Experience, sortOrientation: string) {
  if (sortOrientation === "ASC") {
    return a.experienceContacts.length - b.experienceContacts.length;
  } else {
    return b.experienceContacts.length - a.experienceContacts.length;
  }
}

/**
 * Sorts experiences.
 */
export function sortExperiences(experiences: Experience[], sortField: string, sortOrientation: string) {
  return [...experiences]
    .sort((a, b) => {
      switch (sortField) {
        case SORT_FIELD.EXPERIENCE_CREATED_AT:
          return sortByCreatedAt(a, b, sortOrientation);
        case SORT_FIELD.EXPERIENCE_TITLE:
          return sortByName(a, b, sortOrientation);
        case SORT_FIELD.EXPERIENCE_ACTIVITY:
          return sortByActivity(a, b, sortOrientation);
        case SORT_FIELD.EXPERIENCE_NUM_OF_CONTACTS:
          return sortByExperienceContacts(a, b, sortOrientation);
        default:
          return 0;
      }
    })
    .sort((a, b) => {
      const aValue = a.userCollection;
      const bValue = b.userCollection;
      return Number(bValue) - Number(aValue);
    });
}
