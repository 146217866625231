import type { AxiosError } from "axios";

import type { OrgPropData } from "@/Dashboard/pages/Accounts/types";
import type { Query } from "@/store/v2/queries/types";

import { REQUEST_STATUSES } from "../constants";
import { api } from "./instance";
import { getErrorResponse } from "../utils/helper";

interface UpdateOrganizationData {
  displayName?: string;
  linkedProperties?: OrgPropData[];
  active?: boolean;
  adminName?: string;
  adminEmail?: string;
}

export class OrganizationsService {
  static async getOrganizations(query: Query) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations`,
        params: query,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getOrganizationById(organizationId: number) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/organizations/${organizationId}`,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async updateOrganization(organizationId: number, data: UpdateOrganizationData) {
    try {
      const resp = await api({
        method: "put",
        url: `/api/v2/organizations/${organizationId}`,
        data,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
