import type { DropResult } from "react-beautiful-dnd";

import type { Asset, AssetCategory, AssetTag, ContentType } from "@/Dashboard/pages/AssetLibrary/types";
import type { MenuItem, MenuItemType } from "@/VisualBuilder/components/LeftHandMenu/types";
import { CONTENT_TYPE, EXPERIENCE_PRIMARY_FILTER } from "@/constants";
import type { UrlParams } from "@/hooks/useUrlParams";
import { ExperienceContentTypeService, ExperienceService, ExperienceTagService, PropertyService } from "@/services";
import store from "@/store";
import { updateVisualBuilderData } from "@/store/actions";
import { type ThunkDispatch } from "@/store/hooks";

export function changeSortOrder(args: {
  destinationIndex: number;
  sourceIndex: number;
  masterList: { id: number }[];
  filteredList: { id: number }[];
}) {
  const { destinationIndex, sourceIndex, masterList, filteredList } = args;
  const allItemsOrdered = [...masterList];
  const length = { above: 0, below: 0 };

  // Count how many items are above the FIRST element of the filtered list
  for (const { id: currentId } of masterList) {
    const hasAny = filteredList.some(({ id }) => id === currentId);

    if (hasAny) {
      break;
    }

    length.above += 1;
  }

  // Count how many items are below the LAST element of the filtered list
  for (let i = masterList.length - 1; i >= 0; i--) {
    const currentId = masterList[i].id;
    const hasAny = filteredList.some(({ id }) => id === currentId);

    if (hasAny) {
      break;
    }

    length.below += 1;
  }

  let targetElement = null;
  let index = 0;
  const targetId = filteredList[sourceIndex].id;

  // Find target element, and its index
  while (!targetElement || index < allItemsOrdered.length) {
    const { id } = allItemsOrdered[index];

    if (id === targetId) {
      [targetElement] = allItemsOrdered.splice(index, 1);
    }

    index += 1;
  }

  // Ordering from filtered list; do simple math
  let correctDestIndex =
    sourceIndex < destinationIndex ? length.below + destinationIndex + 1 : length.above + destinationIndex;

  // Ordering from unfiltered list
  if (masterList.length === filteredList.length) {
    correctDestIndex = destinationIndex;
  }

  // Insert target element into destination list
  allItemsOrdered.splice(correctDestIndex, 0, targetElement);

  // Filter for items to show in the filtered list
  const filteredItemsOrdered = allItemsOrdered.reduce(
    (acc, cur) => {
      const hasAny = filteredList.some((element) => element.id === cur.id);

      if (hasAny) {
        acc.push(cur);
      }

      return acc;
    },
    [] as { id: number }[],
  );

  return { filtered: filteredItemsOrdered, all: allItemsOrdered };
}

export async function onChangeTagStatus({
  tag,
  experienceTagIds,
  hiddenTags,
  dispatch,
  params,
  tagFilterId,
}: {
  tag: AssetTag;
  experienceTagIds: Record<number, boolean>;
  hiddenTags: Record<number, boolean>;
  dispatch: ThunkDispatch;
  params: UrlParams;
  tagFilterId: number | null;
}) {
  const { propertyId, experienceId } = params;

  const values = { hidden: !tag.hidden };

  if (!experienceTagIds[tag.id]) {
    await ExperienceTagService.addExperienceTag(propertyId, experienceId, tag.id, values);
  } else {
    await ExperienceTagService.updateExperienceTag(propertyId, experienceId, tag.id, values);
  }

  const updates: {
    tagFilterId?: number | null;
  } = {};

  if (values.hidden && tagFilterId && tagFilterId === tag.id) {
    updates.tagFilterId = null;
  }

  await dispatch(
    updateVisualBuilderData(
      params,
      updates,
      {
        experienceTagIds: { ...experienceTagIds, [tag.id]: true },
        hiddenTags: { ...hiddenTags, [tag.id]: values.hidden },
      },
      true,
    ),
  );
}

export async function onTagNameChangeComplete({
  tag,
  value,
  experienceTagIds,
  tagNames,
  dispatch,
  params,
}: {
  tag: { id: number };
  value: string;
  experienceTagIds: Record<number, boolean>;
  tagNames: Record<number, string>;
  dispatch: ThunkDispatch;
  params: UrlParams;
}) {
  const { propertyId, experienceId } = params;

  const values = { name: value };

  if (!experienceTagIds[tag.id]) {
    await ExperienceTagService.addExperienceTag(propertyId, experienceId, tag.id, values);
  } else {
    await ExperienceTagService.updateExperienceTag(propertyId, experienceId, tag.id, values);
  }

  await dispatch(
    updateVisualBuilderData(
      params,
      {},
      {
        experienceTagIds: { ...experienceTagIds, [tag.id]: true },
        tagNames: { ...tagNames, [tag.id]: values.name },
      },
      true,
    ),
  );
}

export async function onChangeTypeSortOrder({
  typeSortResult,
  contentTypes,
  filteredContentTypes,
  experienceContentTypeIds,
  contentTypesOrdered,
  dispatch,
  params,
}: {
  typeSortResult: DropResult;
  contentTypes: { id: number }[];
  filteredContentTypes: { id: number }[];
  experienceContentTypeIds: Record<number, boolean>;
  contentTypesOrdered: Record<number, number>;
  dispatch: ThunkDispatch;
  params: UrlParams;
}) {
  const { propertyId, experienceId } = params;

  const { source, destination } = typeSortResult;

  const newContentTypes = changeSortOrder({
    destinationIndex: destination?.index as number,
    sourceIndex: source.index,
    masterList: contentTypes,
    filteredList: filteredContentTypes,
  });

  const newExperienceContentTypeIds = { ...experienceContentTypeIds };
  const newContentTypesOrdered = { ...contentTypesOrdered };

  dispatch(
    updateVisualBuilderData(
      params,
      {},
      {
        filteredContentTypes: newContentTypes.filtered,
      },
      false,
    ),
  );

  await Promise.all(
    newContentTypes.all.map((contentType, index) => {
      const values = { order: index + 1 };
      newExperienceContentTypeIds[contentType.id] = true;
      newContentTypesOrdered[contentType.id] = values.order;
      if (!experienceContentTypeIds[contentType.id]) {
        return ExperienceContentTypeService.addExperienceContentType(propertyId, experienceId, contentType.id, values);
      } else {
        return ExperienceContentTypeService.updateExperienceContentType(
          propertyId,
          experienceId,
          contentType.id,
          values,
        );
      }
    }),
  );

  dispatch(
    updateVisualBuilderData(
      params,
      {},
      {
        experienceContentTypeIds: newExperienceContentTypeIds,
        contentTypesOrdered: newContentTypesOrdered,
      },
      true,
    ),
  );
}

export async function onChangeTagSortOrder({
  tagSortResult,
  tags,
  filteredTags,
  experienceTagIds,
  tagsOrdered,
  dispatch,
  params,
}: {
  tagSortResult: DropResult;
  tags: AssetTag[];
  filteredTags: AssetTag[];
  experienceTagIds: Record<number, boolean>;
  tagsOrdered: Record<number, number>;
  dispatch: ThunkDispatch;
  params: UrlParams;
}) {
  const { destination, source } = tagSortResult;

  if (
    destination?.droppableId === "droppableTagList" &&
    source.droppableId === "droppableTagList" &&
    destination.droppableId === source.droppableId
  ) {
    const { propertyId, experienceId } = params;

    const sourceItem = filteredTags[source.index];
    const destinationItem = filteredTags[destination.index];

    const newTags = [...tags];

    const [removed] = newTags.splice((sourceItem?.order ?? 1) - 1, 1);
    newTags.splice((destinationItem?.order ?? 1) - 1, 0, removed);

    const newFilteredTags = [...filteredTags];

    const [removedItem] = newFilteredTags.splice(source.index, 1);
    newFilteredTags.splice(destination.index, 0, removedItem);

    const newExperienceTagIds = { ...experienceTagIds };
    const newTagsOrdered = { ...tagsOrdered };

    await dispatch(
      updateVisualBuilderData(
        params,
        {},
        {
          filteredTags: newFilteredTags,
        },
        false,
      ),
    );

    await Promise.all(
      newTags.map((tag, index) => {
        const values = { order: index + 1 };
        newExperienceTagIds[tag.id] = true;
        newTagsOrdered[tag.id] = values.order;
        if (!experienceTagIds[tag.id]) {
          return ExperienceTagService.addExperienceTag(propertyId, experienceId, tag.id, values);
        } else {
          return ExperienceTagService.updateExperienceTag(propertyId, experienceId, tag.id, values);
        }
      }),
    );

    await dispatch(
      updateVisualBuilderData(
        params,
        {},
        {
          experienceTagIds: newExperienceTagIds,
          tagsOrdered: newTagsOrdered,
        },
        true,
      ),
    );
  }
}

export async function onChangeContentSortOrder({
  contentSortResult,
  tagId,
  mediaItems = [],
  filteredMediaItems = [],
  filteredTagMediaItems,
  contentsOrdered,
  dispatch,
  params,
}: {
  contentSortResult: {
    source: { droppableId: string; index: number };
    destination: { droppableId: string; index: number };
  };
  tagId: number;
  mediaItems: { id: number }[];
  filteredMediaItems: { id: number }[];
  filteredTagMediaItems: Record<number, { id: number }>;
  contentsOrdered: Record<string, number>;
  dispatch: ThunkDispatch;
  params: UrlParams;
}) {
  const { destination, source } = contentSortResult;
  if (
    destination.droppableId === "droppableContentList" &&
    source.droppableId === "droppableContentList" &&
    destination.droppableId === source.droppableId
  ) {
    const newMediaItems = changeSortOrder({
      destinationIndex: destination.index,
      sourceIndex: source.index,
      masterList: mediaItems,
      filteredList: filteredMediaItems,
    });

    const newContentsOrdered = { ...contentsOrdered };

    newMediaItems.all.forEach((mediaItem, index) => (newContentsOrdered[`${tagId}/${mediaItem.id}`] = index + 1));

    await dispatch(
      updateVisualBuilderData(
        params,
        {
          contentsOrdered: newContentsOrdered,
          filteredTagMediaItems: { ...filteredTagMediaItems, [tagId]: newMediaItems.filtered },
        },
        {},
        true,
      ),
    );
  }
}

export async function onChangeTypeStatus({
  contentType,
  experienceContentTypeIds,
  hiddenContentTypes,
  dispatch,
  params,
  typeFilterId,
}: {
  contentType: ContentType;
  experienceContentTypeIds: Record<number, boolean>;
  hiddenContentTypes: Record<number, boolean>;
  dispatch: ThunkDispatch;
  params: UrlParams;
  typeFilterId: number | null;
}) {
  const { propertyId, experienceId } = params;

  const values = {
    hidden: !contentType.hidden,
  };

  if (!experienceContentTypeIds[contentType.id]) {
    await ExperienceContentTypeService.addExperienceContentType(propertyId, experienceId, contentType.id, values);
  } else {
    await ExperienceContentTypeService.updateExperienceContentType(propertyId, experienceId, contentType.id, values);
  }

  const updates: {
    typeFilterId?: number | null;
  } = {};

  if (values.hidden && typeFilterId && typeFilterId === contentType.id) {
    updates.typeFilterId = null;
  }

  await dispatch(
    updateVisualBuilderData(
      params,
      updates,
      {
        experienceContentTypeIds: { ...experienceContentTypeIds, [contentType.id]: true },
        hiddenContentTypes: { ...hiddenContentTypes, [contentType.id]: values.hidden },
      },
      true,
    ),
  );
}

export async function onChangeAssetCategoryStatus({
  assetCategory,
  experienceAssetCategoryIds,
  hiddenAssetCategories,
  params,
  assetCategoryFilterId,
  locked,
}: {
  assetCategory: { id: number };
  experienceAssetCategoryIds: Record<number, boolean>;
  hiddenAssetCategories: Record<number, boolean>;
  params: UrlParams;
  assetCategoryFilterId: number | null;
  locked: boolean;
}) {
  if (locked) return;

  const values = {
    hidden: !hiddenAssetCategories[assetCategory.id],
  };

  const updates: {
    assetCategoryFilterId?: number | null;
  } = {};

  if (values.hidden && assetCategoryFilterId && assetCategoryFilterId === assetCategory.id) {
    updates.assetCategoryFilterId = null;
  }

  await store.dispatch(
    updateVisualBuilderData(
      params,
      updates,
      {
        experienceAssetCategoryIds: { ...experienceAssetCategoryIds, [assetCategory.id]: true },
        hiddenAssetCategories: { ...hiddenAssetCategories, [assetCategory.id]: values.hidden },
      },
      true,
    ),
  );
}

export async function handleAssetMenuClick({
  asset,
  assetCategoryFilterId,
  currentMediaItemId,
  gridView,
  params,
}: {
  asset: Asset;
  assetCategoryFilterId: number | null;
  currentMediaItemId: number | null;
  gridView: boolean;
  params: UrlParams;
}) {
  const { id, xFrameOptions, contentType, link, assetCategoryId } = asset;
  if (
    xFrameOptions &&
    xFrameOptions !== "NONE" &&
    contentType.value === CONTENT_TYPE.LINK &&
    window.confirm("Open link in new tab?")
  ) {
    window.open(link, "_blank");
  }

  const values: {
    assetCategoryFilterId?: number | null;
    currentMediaItemId?: number | null;
    gridView?: boolean;
  } = {};

  if (currentMediaItemId === id && assetCategoryFilterId === assetCategoryId) {
    return;
  }

  if (assetCategoryFilterId !== assetCategoryId) {
    values.assetCategoryFilterId = assetCategoryId;
  }

  values.currentMediaItemId = id;

  if (gridView) {
    values.gridView = false;
  }

  if (Object.keys(values).length) {
    store.dispatch(updateVisualBuilderData(params, values, {}, true));
  }
}

export function toggleContentTypeSelection({
  assetCategoryFilterId,
  assetMenuOpen,
  params,
  parentCategory,
  primaryFilter,
  typeFilterId,
  type,
}: {
  assetCategoryFilterId: number | null;
  assetMenuOpen: boolean;
  params: UrlParams;
  parentCategory: AssetCategory | undefined;
  primaryFilter: string;
  typeFilterId: number | null;
  type: { id: number };
}) {
  if (
    primaryFilter === EXPERIENCE_PRIMARY_FILTER.TAG &&
    typeFilterId === type.id &&
    parentCategory?.id === assetCategoryFilterId &&
    !assetMenuOpen
  ) {
    store.dispatch(updateVisualBuilderData(params, { assetMenuOpen: true }, {}, true));
    return;
  }

  const values: {
    assetCategoryFilterId?: number | null;
    assetMenuOpen?: boolean;
    typeFilterId?: number | null;
  } = {
    typeFilterId: typeFilterId === type.id ? null : type.id,
    assetCategoryFilterId,
  };

  if (
    primaryFilter === EXPERIENCE_PRIMARY_FILTER.TAG &&
    parentCategory &&
    assetCategoryFilterId !== parentCategory.id
  ) {
    values.typeFilterId = type.id;
    values.assetCategoryFilterId = parentCategory.id;
  }

  if (primaryFilter === EXPERIENCE_PRIMARY_FILTER.TAG) {
    values.assetMenuOpen = !!values.assetCategoryFilterId;
  }

  store.dispatch(updateVisualBuilderData(params, values, {}, true));
}

export function toggleAssetCategorySelection({
  assetCategoryFilterId,
  assetMenuOpen,
  category,
  params,
  parentType,
  primaryFilter,
  typeFilterId,
}: {
  assetCategoryFilterId: number | null;
  assetMenuOpen: boolean;
  category: { id: number };
  params: UrlParams;
  parentType: ContentType | undefined;
  primaryFilter: string;
  typeFilterId: number | null;
}) {
  if (
    primaryFilter === EXPERIENCE_PRIMARY_FILTER.TYPE &&
    assetCategoryFilterId === category.id &&
    parentType?.id === typeFilterId &&
    !assetMenuOpen
  ) {
    store.dispatch(updateVisualBuilderData(params, { assetMenuOpen: true }, {}, true));
    return;
  }

  const values: {
    assetCategoryFilterId?: number | null;
    assetMenuOpen?: boolean;
    typeFilterId?: number | null;
  } = {
    assetCategoryFilterId: assetCategoryFilterId === category.id ? null : category.id,
    typeFilterId,
  };

  if (primaryFilter === EXPERIENCE_PRIMARY_FILTER.TYPE && parentType && typeFilterId !== parentType.id) {
    values.assetCategoryFilterId = category.id;
    values.typeFilterId = parentType.id;
  }

  if (primaryFilter === EXPERIENCE_PRIMARY_FILTER.TYPE) {
    values.assetMenuOpen = !!values.typeFilterId;
  }

  store.dispatch(updateVisualBuilderData(params, values, {}, true));
}

export function toggleAssetCategoryOpen({
  params,
  category,
  assetCategoriesToggled,
  locked,
  isInCustomizeMode,
}: {
  params: UrlParams;
  category: { id: number };
  assetCategoriesToggled: Record<number, boolean>;
  locked: boolean;
  isInCustomizeMode: boolean;
}) {
  if (locked && isInCustomizeMode) return;

  const values = {
    assetCategoriesToggled: { ...assetCategoriesToggled, [category.id]: !assetCategoriesToggled[category.id] },
  };
  store.dispatch(updateVisualBuilderData(params, values, {}, true));
}

export function toggleContentTypeOpen({
  params,
  type,
  typesToggled,
  locked,
  isInCustomizeMode,
}: {
  params: UrlParams;
  type: { id: number };
  typesToggled: Record<number, boolean>;
  locked: boolean;
  isInCustomizeMode: boolean;
}) {
  if (locked && isInCustomizeMode) return;

  const values = { typesToggled: { ...typesToggled, [type.id]: !typesToggled[type.id] } };
  store.dispatch(updateVisualBuilderData(params, values, {}, true));
}

/**
 * Renames an asset category.
 */
export async function renameAssetCategory({
  assetCategoriesOrdered,
  assetCategory,
  assetCategoryNames,
  experienceAssetCategoryIds,
  params,
  value,
}: {
  assetCategoriesOrdered: Record<number, number>;
  assetCategory: { id: number };
  assetCategoryNames: Record<number, string>;
  experienceAssetCategoryIds: Record<number, boolean>;
  params: UrlParams;
  value: string;
}) {
  const { propertyId, experienceId } = params;

  const values = { name: value };

  const item = {
    name: value,
    assetCategoryId: assetCategory.id,
    order: assetCategoriesOrdered?.[assetCategory?.id] || 0,
  };

  await ExperienceService.updateExperience(Number(propertyId), Number(experienceId), {
    experienceAssetCategories: [item],
  });

  await store.dispatch(
    updateVisualBuilderData(
      params,
      {},
      {
        experienceAssetCategoryIds: { ...experienceAssetCategoryIds, [assetCategory.id]: true },
        assetCategoryNames: { ...assetCategoryNames, [assetCategory.id]: values.name },
      },
      true,
    ),
  );
}

/**
 * Changes a menu order.
 */
export async function changeMenuItemsOrder({
  result,
  items,
  menuItems,
  itemType,
  parentId,
  params,
  isPropertyBasedTab,
}: {
  result: DropResult;
  items: AssetCategory[] | ContentType[] | Asset[];
  menuItems: MenuItem[];
  itemType: MenuItemType;
  parentId: number;
  params: UrlParams;
  isPropertyBasedTab: boolean;
}) {
  if (!result.destination) {
    return;
  }
  if (
    result.destination.droppableId === result.source.droppableId &&
    result.destination.index === result.source.index
  ) {
    return;
  }

  const { source, destination } = result;
  const { propertyId, experienceId } = params as { propertyId: number; experienceId: number };

  const sorted = changeSortOrder({
    destinationIndex: destination?.index as number,
    sourceIndex: source?.index as number,
    masterList: items,
    filteredList: items,
  });

  let order = 0;
  for (const item of sorted.all) {
    const sectionItem = menuItems.find(
      (menuItem) => menuItem.parentId === parentId && menuItem.itemId === item.id && menuItem.itemType === itemType,
    );
    if (sectionItem) {
      sectionItem.order = ++order;
    } else {
      menuItems.push({
        itemType,
        parentId,
        itemId: item.id,
        order: ++order,
      });
    }
  }

  if (isPropertyBasedTab) {
    store.dispatch(updateVisualBuilderData(params, {}, { propertyMenuItems: menuItems }, true));

    await PropertyService.updateProperty(propertyId, { propertyMenuItems: menuItems });
  } else {
    store.dispatch(updateVisualBuilderData(params, {}, { experienceMenuItems: menuItems }, true));

    await ExperienceService.updateExperience(propertyId, experienceId, { experienceMenuItems: menuItems });
  }
}

export async function hideMenuItem({
  itemType,
  contentType,
  assetCategory,
  experienceMenuItems,
  params,
  locked,
}: {
  itemType: MenuItemType;
  contentType: { id: number };
  assetCategory: { id: number };
  experienceMenuItems: MenuItem[];
  params: UrlParams;
  locked: boolean;
}) {
  if (locked) return;

  const { propertyId, experienceId } = params;

  const parentId = itemType === "asset-category" ? contentType.id : assetCategory.id;
  const itemId = itemType === "asset-category" ? assetCategory.id : contentType.id;

  const item = experienceMenuItems.find(
    (item) => item.parentId === parentId && item.itemId === itemId && item.itemType === itemType,
  );

  if (item) {
    item.hidden = !item.hidden;
  } else {
    experienceMenuItems.push({
      itemType,
      parentId: parentId,
      itemId: itemId,
      order: 0,
      hidden: true,
      name: "",
    });
  }

  await ExperienceService.updateExperience(Number(propertyId), Number(experienceId), { experienceMenuItems });

  const updates = {
    experienceMenuItems,
  };

  await store.dispatch(updateVisualBuilderData(params, {}, updates, true));
}

/**
 * Renames a menu item.
 */
export async function renameMenuItem({
  value,
  itemType,
  contentType,
  assetCategory,
  experienceMenuItems,
  params,
}: {
  value: string;
  itemType: MenuItemType;
  contentType: { id: number };
  assetCategory: { id: number };
  experienceMenuItems: MenuItem[];
  params: UrlParams;
}) {
  const { propertyId, experienceId } = params;

  const parentId = itemType === "asset-category" ? contentType.id : assetCategory.id;
  const itemId = itemType === "asset-category" ? assetCategory.id : contentType.id;

  const item = experienceMenuItems.find(
    (item) => item.parentId === parentId && item.itemId === itemId && item.itemType === itemType,
  );

  if (item) {
    item.name = value;
  } else {
    experienceMenuItems.push({
      itemType,
      parentId: parentId,
      itemId: itemId,
      order: 0,
      hidden: false,
      name: value,
    });
  }

  await ExperienceService.updateExperience(Number(propertyId), Number(experienceId), { experienceMenuItems });

  const updates = { experienceMenuItems };

  await store.dispatch(updateVisualBuilderData(params, {}, updates, true));
}

/**
 * Sorts menu items based on their order. Starts alphabetized by name, sorts by order if available.
 */
export function sortMenuItems({
  parentId,
  itemType,
  items,
  menuItems,
}: {
  parentId: number;
  itemType: MenuItemType;
  items: Asset[] | AssetCategory[] | ContentType[];
  menuItems: MenuItem[];
}) {
  const alphabetized = items.sort((a, b) =>
    (a.name?.toLowerCase() ?? "").localeCompare(b.name?.toLowerCase() ?? "", undefined, {
      numeric: true,
    }),
  );

  return alphabetized.sort((a, b) => {
    const a1 = menuItems.find(
      (item) => item.parentId === parentId && item.itemId === a.id && item.itemType === itemType,
    );
    const b1 = menuItems.find(
      (item) => item.parentId === parentId && item.itemId === b.id && item.itemType === itemType,
    );
    return (a1?.order ?? 0) - (b1?.order ?? 0);
  });
}
